<template>
	<div class="row" id="filters">
		<div class="col-xs-12">
			<div class="row">
				<div class="col-xs-3 no-padding">
					<input placeholder="Namn: " disabled>
				</div>

				<div class="col-xs-9 no-padding">
					<input type="text" :value="filters === [] ? '': getFilterValueFor('name')" @input="$emit('search', {field: 'name', query: $event.target.value})">
				</div>
			</div>
		</div>

		<div class="col-xs-12">
			<div class="row">
				<div class="col-xs-3 no-padding">
					<input placeholder="Titlar: " disabled>
				</div>

				<div class="col-xs-9 no-padding">
					<input type="text" :value="filters === [] ? '': getFilterValueFor('titles')" @input="$emit('search', {field: 'titles', query: $event.target.value})">
				</div>
			</div>
		</div>

		<div class="col-xs-12">
			<div class="row range-parent">
				<div class="col-xs-3 no-padding">
					<input placeholder="Född: " disabled>
				</div>

				<div class="col-xs-9 no-padding">
					<div class="row">
						<div class="col-xs-5">
							<input type="number" :value="filters === [] ? '': getFilterValueFor('s_year')" placeholder="+" @input="$emit('search', {field: 's_year', query: $event.target.value})">
						</div>
						<div class="col-xs-2">
							<input class="center" placeholder="Död:" disabled>
						</div>
						<div class="col-xs-5">
							<input type="number" :value="filters === [] ? '': getFilterValueFor('e_year')" placeholder="✝" @input="$emit('search', {field: 'e_year', query: $event.target.value})">
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-xs-12">
			<div class="row">
				<div class="col-xs-3 no-padding">
					<input placeholder="Plats: " disabled>
				</div>

				<div class="col-xs-9 no-padding">
					<input type="text" :value="filters === [] ? '': getFilterValueFor('cemetery')" @input="$emit('search', {field: 'cemetery', query: $event.target.value})">
				</div>
			</div>
		</div>

		<div class="col-xs-12">
			<hr>
			<br/>
		</div>

		<div class="col-xs-12">
			<div class="row">
				<div class="col-xs-12">
					<button @click="$emit('clearFilters')">Töm</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "SearchBar",
	props: ['filters'],
	methods: {
		getFilterValueFor(field) {
			for (const filter of this.filters) {
				if (filter.field === field) {
					return filter.query
				}
			}

			return ''
		}
	}
}
</script>

<style scoped>
#filters {
	padding-top: 30px;
	padding-bottom: 30px;
	padding-left: 10px;
	padding-right: 10px;
	margin-bottom: 2vh;
}

.no-padding {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.no-padding input {
	width: 100%;
}

.center {
	text-align: center;
}
</style>
