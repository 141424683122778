<template>
	<div class="row">
		<div class="col-md-4 col-sm-12">
			<keep-alive>
				<SearchBar :filters="filters" @search="updateFilters" @clearFilters="filters = []"/>
			</keep-alive>
		</div>
		<div class="col-md-8 col-xs-12">
			<keep-alive>
				<SearchResults :filters="filters"/>
			</keep-alive>
		</div>
	</div>
</template>

<script>
import SearchBar from "@/components/Search/SearchBar";
import SearchResults from "@/components/Search/SearchResults";
export default {
	name: "Search",
	components: {SearchResults, SearchBar},
	data() {
		return {
			filters: []
		}
	},
	mounted() {
			if (this.$store.state.filtersBackup.length > 0 && this.$store.state.isBack) {
				this.filters = this.$store.state.filtersBackup
				this.$store.commit('setFiltersBackup', [])
				this.$store.commit('setIsBack', false)
			}
	},
	methods: {
		updateFilters(e) {
			for (let filter of this.filters) {
				if (filter.field === e.field) {
					this.filters[this.filters.indexOf(filter)].query = e.query

					return
				}
			}

			this.filters.push(e)
		}
	}
}
</script>

<style scoped>

</style>
