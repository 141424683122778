<template>
	<div id="footer">
		<p>
			&copy; Minnesvårdarna rf. 2021
		</p>
	</div>
</template>

<script>
export default {
	name: "Footer"
}
</script>

<style scoped>
#footer {
	max-width: 100%;
	overflow-x: hidden;
	margin: 0 auto;
	text-align: center;
	background-color: var(--accent-green);
	padding: 10px 20px;
}

#footer p {
	color: var(--light-grey);
}
</style>
