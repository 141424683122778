import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		filtersBackup: [],
		isBack: false
	},
	mutations: {
		setFiltersBackup(state, filters) {
			state.filtersBackup = filters
		},
		setIsBack(state, status) {
			state.isBack = status
		}
	},
	actions: {},
	modules: {}
})
