<template>
	<div class="row container-fluid">
		<div class="col-xs-12">
			<div class="row">
				<div v-for="(match, index) in filteredData" :key="index" class="col-md-4 col-sm-6 col-xs-12 datum-card" @click="openDatum(match)">
					<h2 v-if="match.name && match.name !== '-' && match.name" v-text="match.name"></h2>
					<p v-if="match.titles && match.titles !== '-' && match.titles" v-text="match.titles + '.'"></p>
					<br/>
					<h3 v-if="match.year && match.year !== '-' && match.year">- <strong v-text="match.year"></strong>.</h3>
					<h3 v-if="match.cemetery && match.cemetery !== '-' && match.cemetery"><span v-text="'- ' + match.cemetery"></span>.</h3>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "SearchResults",
	props: ['filters'],
	data() {
		return {
			data: {},
		}
	},
	mounted() {
		this.getData()
	},
	methods: {
		getData() {
			this.data = require('@/assets/data.json')
		},
		openDatum(datum) {
			this.$store.commit('setFiltersBackup', this.filters)
			this.$router.push(`/person/${datum.id}`)
		},
	},
	computed: {
		filteredData() {
			const final = []

			for (const datumKey in this.data) {
				const datum = this.data[datumKey]
				const filterStatuses = []

				this.filters.forEach((filter, filterI) => {
					if (this.filters[filterI].query.length > 0) {
						if (filter.field in datum) {
							filterStatuses.push(datum[filter.field].toLowerCase().includes(filter.query.toLowerCase()))
						} else {
							filterStatuses.push(false)
						}
					}
				})

				if (!filterStatuses.includes(false)) {
					final.push(this.data[datumKey])
				}
			}

			return final
		}
	}
}
</script>

<style scoped>
.datum-card {
	padding: 20px 10px;
	background-color: var(--white);
	border: 1px solid var(--light-grey);
	border-top: 5px solid var(--accent);
	margin-bottom: 20px;
}

.datum-card h2 {
	margin: 0;
	font-size: 1rem;
	color: var(--black);
}

.datum-card h3 {
	margin: 0;
	margin-bottom: 5px;
	color: var(--black);
	opacity: .9;
	font-size: .9rem;
	font-weight: normal;
}

.datum-card p {
	margin: 0;
	padding-top: 5px;
	color: var(--text);
	font-size: .8rem;
}

.datum-card:hover {
	cursor: pointer;
	background-color: var(--light-grey);
}
</style>
