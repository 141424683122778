<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-xs-12">
				<!-- header -->
			</div>
			<div class="col-xs-12">
				<Search />
			</div>
		</div>
	</div>
</template>

<script>
import Search from "@/components/Search";
export default {
	name: "Home",
	components: {Search}
}
</script>

<style scoped>

</style>
