<template>
	<div id="app">
		<Navbar />
		<router-view></router-view>
		<Footer />
	</div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

export default {
	components: {Footer, Navbar}
}
</script>

<style>
:root {
	--light-grey: #ebedee;
	--white: #fff;
	--black: #333c4b;
	--text: #6a707c;
	--accent: #cfddda;
	--accent-blue: #bbd2d8;
	--accent-green: #28401C;
}

* {
	font-family: sans-serif;
}

input, textarea {
	width: 90%;
	border: none;
	border: 1px solid var(--light-grey);
	padding: 8px;
	margin-left: auto;
	margin-right: auto;
	margin-top: 2px;
	margin-bottom: 2px;
	font-size: .8rem;
	color: var(--text);
}

textarea {
	resize: vertical;
}

button {
	padding: 8px 15px;
	font-size: .8rem;
	background-color: var(--accent-green);
	color: var(--light-grey);
	border: none;
	border-radius: 10px;
}

button:hover, button:active {
	cursor: pointer;
	color: var(--black);
}
</style>
