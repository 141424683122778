<template>
	<div id="nav">
		<div id="inner" class="row">
			<div id="text-holder" class="col-sm-9 col-xs-12">
				<h1 @click="$router.push('/')">Här vilar kända finländare</h1>
			</div>
			<div id="logo-holder" class="col-sm-3 col-xs-12">
				<img @click="goToUrl('https://minnesvardarna.fi')" src="../assets/img/banner.png" alt="Minnesvårdarna">
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Navbar",
	methods: {
		goToUrl(url) {
			window.location = url
		}
	}
}
</script>

<style scoped>
#nav {
	width: 100%;
	max-width: 100%;
	overflow-x: hidden;
	margin: 0;
	margin-bottom: 20px;
	z-index: 98;
	background-color: var(--accent-green);
}

#text-holder, #logo-holder {
	display: flex;
	align-items: center;
	padding: 0 30px;
}

#text-holder h1 {
	color: var(--light-grey);
	margin-right: auto;
}

#logo-holder img {
	max-width: 80%;
	margin-left: auto;
}

#logo-holder img:hover, #text-holder h1:hover {
	cursor: pointer;
}

@media screen and (max-width: 780px) {
	#text-holder {
		padding: 10px 0;
	}

	#text-holder h1 {
		margin: 0 auto;
		font-size: 1.3rem;
	}

	#logo-holder {
		display: none;
	}
}
</style>
